import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import Resume from "./resume"
import PhotoPage from "./PhotoPage"
import reportWebVitals from './reportWebVitals';
import ThemeContext from "./ThemeContext";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
    <ThemeContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/resume" element={<Resume/>} />
            <Route path="/photos" element={<PhotoPage/>} />
        </Routes>
      </BrowserRouter>
    </ThemeContext>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
